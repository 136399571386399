import { paramsFromObject } from "../assets/js/utils/functions";
import {
  CreateCouponParams,
  CreateCustomItemParams,
  CreateDiscountParams,
  CreateItemsParams,
  DeleteCouponParams,
  DeleteDiscountParams,
  DeleteItemParams,
  EditItemParams,
  EditItemQuantityParams,
  GetAllItemsParams,
  GetCustomItemsParams,
  GetDiscountItemParams,
  GetItemsParams,
  GetStoreItemsParams,
  ImportItemsParams,
  BulkUpdateItemsParams,
  PaginateSearchParams,
  UpdateCouponParams,
  UpdateCustomItemParams,
  UpdateDiscountParams,
} from "./interfaces/items.interface";
import { request } from "./utils";

const CreateItems = (data: CreateItemsParams) => {
  return request("items", "post", { data });
};

const ImportItems = (data: ImportItemsParams) => {
  return request("items/import-items", "post", { data });
};

const GetAllItems = (data: GetAllItemsParams) => {
  const params = paramsFromObject(data);
  return request(`items/all?${params}`, "get");
};

const GetItems = (data: GetItemsParams) => {
  const params = paramsFromObject(data);
  return request(`items?${params}`, "get");
};

const GetItem = ({ slug }) => {
  return request(`items/${slug}`, "get");
};

const EditItems = (data: EditItemParams) => {
  return request(`items/${data.id}`, "put", { data: data.item });
};

const BulkUpdateItems = (data: BulkUpdateItemsParams) => {
  return request(`items/bulk-update`, "put", { data });
};

const UpdateFeaturedItem = (data: EditItemParams) => {
  return request(`items/featured/${data.id}`, "put", { data: data.item });
};

const EditItemQuantities = (data: EditItemQuantityParams) => {
  return request("items/quantities", "put", { data });
};

const DeleteItem = (data: DeleteItemParams) => {
  return request(`items/${data.id}`, "delete");
};

const GetStoreItems = (data: GetStoreItemsParams) => {
  const params = paramsFromObject(data);
  return request(`items/public?${params}`, "get");
};

const CreateDiscount = (data: CreateDiscountParams) => {
  return request("discounts", "post", { data });
};

const GetDiscounts = (data: PaginateSearchParams) => {
  const params = paramsFromObject(data);
  return request(`discounts?${params}`, "get");
};

const UpdateDiscount = (data: UpdateDiscountParams) => {
  return request("discounts", "put", { data });
};

const DeleteDiscount = (data: DeleteDiscountParams) => {
  return request(`discounts/${data.id}`, "delete");
};

const GetDiscountItems = (data: GetDiscountItemParams) => {
  return request(`discounts/${data.id}/items`, "get");
};

const GetCoupons = (data: PaginateSearchParams) => {
  const params = paramsFromObject(data);
  return request(`coupons?${params}`, "get");
};

const GetCouponByCode = (data: { code: string }) => {
  return request(`coupons/${data.code}`, "get");
};

const CreateCoupon = (data: CreateCouponParams) => {
  return request("coupons", "post", { data });
};

const UpdateCoupon = (data: UpdateCouponParams) => {
  return request("coupons", "put", { data });
};

const DeleteCoupon = (data: DeleteCouponParams) => {
  return request(`coupons/${data.id}`, "delete");
};

//  CUSTOM ITEMS

const GetCustomItems = (data: GetCustomItemsParams) => {
  const params = paramsFromObject(data);
  return request(`custom-items?${params}`, "get");
};
const CreateCustomItem = (data: CreateCustomItemParams) => {
  return request("custom-items", "post", { data });
};

const UpdateCustomItem = (data: UpdateCustomItemParams) => {
  const id = data.id;
  delete data.id;
  return request(`custom-items/${id}`, "put", { data });
};

const DeleteCustomItem = ({ id }) => {
  return request(`custom-items/${id}`, "delete");
};

const ConvertImagesToStrings = (data: { images: string[] }) => {
  return request("utils/images-to-strings", "post", { data });
};

const ExtractMenuItemsFromImage = (data: { menu_image: string }) => {
  return request("utils/extract-menu-items", "post", { data });
};

const GetVariantTemplates = () => {
  return request("items/variant-templates", "get");
};

export {
  ConvertImagesToStrings,
  CreateCoupon,
  CreateCustomItem,
  CreateDiscount,
  CreateItems,
  DeleteCoupon,
  DeleteCustomItem,
  DeleteDiscount,
  DeleteItem,
  EditItemQuantities,
  EditItems,
  GetAllItems,
  GetCouponByCode,
  GetCoupons,
  GetCustomItems,
  GetDiscountItems,
  GetDiscounts,
  GetItem,
  GetItems,
  GetStoreItems,
  ImportItems,
  UpdateCoupon,
  UpdateCustomItem,
  UpdateDiscount,
  UpdateFeaturedItem,
  ExtractMenuItemsFromImage,
  GetVariantTemplates,
  BulkUpdateItems,
};
