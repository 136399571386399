import { paramsFromObject } from "@/assets/js/utils/functions";
import {
  CancelSubscriptionToggleParams,
  ChangePlanParams,
  GetPlansParams,
  GetTokenPurchaseHistoryParams,
} from "./interfaces";
import { request } from "./utils";

const CreateFreeSubscription = (data: ChangePlanParams) => {
  return request("subscriptions", "post", { data });
};

const SwitchToFreePlan = (data: ChangePlanParams) => {
  return request("subscriptions/switch-to-free-plan", "post", { data });
};

const CancelSubscriptionToggle = (data: CancelSubscriptionToggleParams) => {
  return request("subscriptions/cancel-subscription", "post", { data });
};

const GetPlans = (data: GetPlansParams) => {
  const params = paramsFromObject(data);
  return request(`plans?${params}`, "get");
};

const GetTokenPurchaseHistory = (data: GetTokenPurchaseHistoryParams) => {
  const params = paramsFromObject(data);
  return request(`subscriptions/token-history?${params}`, "get");
};

export { CreateFreeSubscription, SwitchToFreePlan, CancelSubscriptionToggle, GetPlans, GetTokenPurchaseHistory };
