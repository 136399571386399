import { ImportChowdeckItemsParams } from "./interfaces/chowdeck";
import { request } from "./utils";

const GetChowdeckItems = () => {
  return request(`stores/chowdeck/menu-items`, "get");
};

const ImportChowdeckItems = (data: ImportChowdeckItemsParams) => {
  return request(`stores/chowdeck/import-items`, "post", { data });
};

export { GetChowdeckItems, ImportChowdeckItems };
