import { COUNTRIES, CURRENCIES, PLAN_TYPE } from "../../interfaces";

const VIEW_MODES = {
  grid: "Grid View",
  card: "Swiping Card",
  horizontal: "Horizontal View",
};

const ORDER_STATUSES: { [key: string]: { text: string; suffix: string } } = {
  PENDING: {
    text: "Received",
    suffix: "was",
  },
  PROCESSING: {
    text: "Processing",
    suffix: "started",
  },
  FULFILLED: {
    text: "Fuilfilled",
    suffix: "was",
  },
  CANCELLED: {
    text: "Cancelled",
    suffix: "was",
  },
  ABANDONED: {
    text: "Abandoned",
    suffix: "was",
  },
};

const MAXIMUM_WITHDRAWAL_AMOUNT = 1_000_000;
const MINIMUM_WITHDRAWAL_AMOUNT = 250;

export const withdrawalFeeCalculator = (amount: number) => {
  if (amount < 100_00) return 25_00;
  else if (amount < 10_000_00) return 50_00;
  else if (amount < 100_000_00) return 75_00;
  else return 100_00;
};

const WHATSAPP_LINK = "https://api.whatsapp.com/send/?phone=2349042550548";
const COMMUNITY_LINK = {
  NG: "https://chat.whatsapp.com/HOxVWwX0cE3JDLGl5JtPh4",
  GH: "https://chat.whatsapp.com/LweESv4ut397qfjeVuEMeN",
};

export {
  VIEW_MODES,
  ORDER_STATUSES,
  MINIMUM_WITHDRAWAL_AMOUNT,
  MAXIMUM_WITHDRAWAL_AMOUNT,
  WHATSAPP_LINK,
  COMMUNITY_LINK,
};

export const SUBSCRIPTION_CARD_DISCOUNT = 5;

export const CURRENCY_OPTIONS = [
  {
    value: CURRENCIES.NGN,
    text: "Naira (₦)",
  },
  {
    value: CURRENCIES.GHC,
    text: "Cedi (₵)",
  },
  {
    value: CURRENCIES.ZAR,
    text: "Rand (R)",
  },
  {
    value: CURRENCIES.KES,
    text: "Shillings (K)",
  },
  {
    value: CURRENCIES.USD,
    text: "Dollar ($)",
  },
  {
    value: CURRENCIES.CAD,
    text: "Canadian Dollars ($)",
  },
  {
    value: CURRENCIES.GBP,
    text: "Pound (£)",
  },
];

export const CURRENCY_COUNTRY_MAP = {
  [CURRENCIES.GHC]: COUNTRIES.GH,
  [CURRENCIES.NGN]: COUNTRIES.NG,
  [CURRENCIES.ZAR]: COUNTRIES.ZA,
  [CURRENCIES.KES]: COUNTRIES.KE,
};

export const COUNTRY_CURRENCY_MAP = {
  [COUNTRIES.NG]: CURRENCIES.NGN,
  [COUNTRIES.GH]: CURRENCIES.GHC,
  [COUNTRIES.ZA]: CURRENCIES.ZAR,
  [COUNTRIES.KE]: CURRENCIES.KES,
};

export const paymentsEnabledCurrencies = [
  CURRENCIES.NGN,
  CURRENCIES.GHC,
  CURRENCIES.KES,
  CURRENCIES.ZAR,
  CURRENCIES.USD,
  CURRENCIES.GBP,
];

export const CURRENCY_FLAG_MAP = {
  [CURRENCIES.EUR]: "🇪🇺",
  [CURRENCIES.GBP]: "🇬🇧",
  [CURRENCIES.NGN]: "🇳🇬",
  [CURRENCIES.GHC]: "🇬🇭",
  [CURRENCIES.USD]: "🇺🇸",
  [CURRENCIES.ZAR]: "🇿🇦",
  [CURRENCIES.KES]: "🇰🇪",
  [CURRENCIES.CAD]: "🇨🇦",
};

export const CURRENCIES_WITH_WITHDRAWALS_ENABLED = [CURRENCIES.NGN, CURRENCIES.GHC, CURRENCIES.ZAR, CURRENCIES.KES];

export enum VERIFICATION_METHODS {
  NIN = "NIN",
  INTERNATIONAL_PASSPORT = "INTERNATIONAL_PASSPORT",
  DRIVERS_LINCENSE = "DRIVERS_LICENSE",
  VOTERS_CARD = "VOTERS_CARD",
  SSNIT_ID = "SSNIT_ID",
  NATIONAL_ID = "NATIONAL_ID",
}

export const PLAN_HOOKS = {
  [PLAN_TYPE.BASIC]: {
    hook: "Upload up to 100 products",
  },
  [PLAN_TYPE.BUSINESS_PLUS]: {
    hook: "Upload unlimited products",
  },
  [PLAN_TYPE.STARTER]: {
    hook: "Upload up to 10 products",
  },
  [PLAN_TYPE.KITCHEN]: {
    hook: "Take orders from customers via chowbot",
  },
};

export const COUNTRY_VATS = {
  [COUNTRIES.GH]: {
    label: "15%",
    value: 0.15,
  },
  [COUNTRIES.KE]: {
    label: "16%",
    value: 0.15,
  },
  [COUNTRIES.ZA]: {
    label: "15%",
    value: 0.15,
  },
  [COUNTRIES.NG]: {
    label: "7.5%",
    value: 0.075,
  },
};

export const addCardDiscount = (amount: number) => amount - (amount * SUBSCRIPTION_CARD_DISCOUNT) / 100;

export const ACCEPTABLE_TOKEN_AMOUNTS = [50, 100, 250, 300, 400, 500, 1000, 1500, 2000, 2500];
export const PRICE_PER_TOKEN = 40;
