import { COUNTRIES } from ".";
import { InvoiceInterface } from "./invoices";
import { CURRENCIES } from "./stores";

export enum PAYMENT_TYPES {
  INVOICE = "INVOICE",
  SUBSCRIPTION = "SUBSCRIPTION",
  DELIVERY = "DELIVERY",
  WALLET = "WALLET",
  TEST = "TEST",
  TOKEN_PURCHASE = "TOKEN_PURCHASE",
}

export enum PAYMENT_METHODS {
  PAYSTACK = "PAYSTACK",
  TRF_MOMO = "TRF_MOMO",
  TRANSFER = "TRANSFER",
  ZILLA = "ZILLA",
  DIRECT_TRANSFER = "DIRECT_TRANSFER",
  MONO_DIRECT_PAY = "MONO_DIRECT_PAY",
  THEPEER = "THEPEER",
  WALLET = "WALLET",
  UNKNOWN = "UNKNOWN", //for receipts
  CATLOG_CREDIT = "CATLOG_CREDITS",
  CARD = "CARD",
  STARTBUTTON = "STARTBUTTON",
  MOMO = "MOMO",
  STRIPE = "STRIPE",
  LEATHERBACK = "LEATHERBACK",
}

export interface PaymentMethodData {
  enabled: boolean;
  payment_types: PAYMENT_TYPES[];
  currencies: CURRENCIES[];
  countries: COUNTRIES[];
  name: string;
  type: PAYMENT_METHODS;
  created_at: string;
  updated_at: string;
  id: string;
  meta: {
    balance?: number;
    enabled?: boolean;
  };
  discount?: number;
}

export interface PaymentCoupon {
  active: boolean;
  type: "percentage" | "fixed";
  coupon_code: string;
  quantity: number;
  payment_type: PAYMENT_TYPES;
  percentage: number;
  end_date: string;
  discount_amount: number;
  discount_cap: number;
  created_at: string;
  updated_at: string;
  id: string;
}

export enum PAYMENT_STATUS {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export enum PAYMENT_PROVIDERS {
  BLOCHQ = "BLOCHQ",
  MONNIFY = "MONNIFY",
  FLUTTERWAVE = "FLUTTERWAVE",
  SQUAD = "SQUAD",
  SAME_AS_METHOD = "SAME_AS_METHOD",
  // PAYSTACK = 'PAYSTACK',
}
export interface PaymentCustomer {
  id: string;
  name: string;
  phone: string;
  email: string;
}

export interface CouponMetaData {
  coupon_code: string;
  id: string;
  discount: number;
  original_amount?: number;
}

export interface PaymentMeta {
  plan?: string;
  plan_option?: string;
  invoice?: string;
  subscription?: string;
  delivery?: string;
  transfer?: string;
  is_test_payment?: boolean;
  monnify_account?: any;
  coupon?: CouponMetaData;
  store?: string;
  chowbot?: {
    session_id: string;
  };
  token_amount?: number;
}

export interface PaymentInterface {
  id: string;
  created_at?: Date;
  updated_at?: Date;
  reference: string;
  vendorReference: string;
  owner: string;
  store: string;
  amount: number;
  amount_with_charge: number;
  gateway_charge: number;
  gateway_amount_settled: number;
  merchant_fees?: number;
  payment_method_type?: string;
  plan?: string;
  plan_option?: string;
  subscription?: string;
  payment_method: PAYMENT_METHODS;
  status: PAYMENT_STATUS;
  type: PAYMENT_TYPES;
  meta: PaymentMeta;
  currency?: CURRENCIES;
  provider?: PAYMENT_PROVIDERS;
  partner_payment?: string;
  error?: string;
  customer?: PaymentCustomer;
  invoice?: InvoiceInterface;
}
