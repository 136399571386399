import { regions } from "@/assets/js/utils/lgas-gh";
import {
  GetInstagramUsernameDataParams,
  GetProductDetailsFromCaptionParams,
  GetProductDetailsFromMultipleCaptionsParams,
} from "./interfaces";
import { request } from "./utils";

const FetchInstagramMetadata = (data: GetInstagramUsernameDataParams) => {
  return request(
    `get-metatags-from-page?url=https://instagram.com/${data.username}`,
    "get",
    { headers: { Authorization: undefined } },
    undefined,
    "https://main--jocular-biscochitos-d6a87e.netlify.app/api"
  );
};

const GetProductDetailsFromCaption = (data: GetProductDetailsFromCaptionParams) => {
  return request(`utils/get-product-details-from-caption`, "post", { data });
};

const GetProductDetailsFromMultipleCaptions = (data: GetProductDetailsFromMultipleCaptionsParams) => {
  return request(`utils/get-product-details-from-multiple-captions`, "post", { data });
};

export { FetchInstagramMetadata, GetProductDetailsFromCaption, GetProductDetailsFromMultipleCaptions };
