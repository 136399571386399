import { paramsFromObject } from "../assets/js/utils/functions";
import {
  ConfirmOrderParams,
  CreateCustomerParams,
  CreateOrderParams,
  DeleteCustomerParams,
  ExportOrdersParams,
  GenerateInvoiceParams,
  GetCustomerParams,
  GetCustomersParams,
  GetOrderParms,
  GetOrdersParams,
  GetOrderStatisticsParams,
  PreviewOrderParams,
  UpadateCustomerParams,
  UpdateOrderParams,
  UpdatePaymentStatusParams,
} from "./interfaces/orders-customers.interface";
import { request } from "./utils";

const CreateOrder = (data: CreateOrderParams) => {
  return request("orders/seller", "post", { data });
};

const PreviewOrder = (data: PreviewOrderParams) => {
  return request(`orders/preview/${data.id}?phone=${data.phone}`, "get");
};

const ConfirmOrder = (data: ConfirmOrderParams) => {
  return request("orders/confirm", "post", { data: { order: data.id } });
};

const GetOrders = (data: GetOrdersParams) => {
  const params = paramsFromObject(data);
  return request(`orders?${params}`, "get");
};

const GetCouponOrders = (data: { coupon_code: string }) => {
  return request(`orders/coupon/${data.coupon_code}`, "get");
};

const GetOrder = (data: GetOrderParms) => {
  return request(`orders/${data.id}`, "get");
};

const UpdateOrder = (data: UpdateOrderParams) => {
  const orderId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`orders/${orderId}`, "put", { data: dataCopy });
};

const UpdateOrderPaymentStatus = (data: UpdatePaymentStatusParams) => {
  return request(`orders/${data.id}/payment`, "put", { data: {} });
};

const GenerateInvoiceFromOrder = (data: GenerateInvoiceParams) => {
  return request(`orders/${data.order_id}/generate-invoice`, "post", {});
};

const GetCustomers = (data: GetCustomersParams) => {
  const params = paramsFromObject(data);
  return request(`customers?${params}`, "get");
};

const GetCustomer = (data: GetCustomerParams) => {
  return request(`customers/${data.id}`, "get");
};

const CreateCustomer = (data: CreateCustomerParams) => {
  return request("customers", "post", { data });
};

const UpdateCustomer = (data: UpadateCustomerParams) => {
  const customerId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`customers/${customerId}`, "put", { data: dataCopy });
};

const GetStoreOrderStatistics = (data: GetOrderStatisticsParams) => {
  const params = paramsFromObject({ filter: data.filter });

  return request(`orders/statistics?${params}`, "get");
};

const ExportOrders = async (data: ExportOrdersParams) => {
  const params = paramsFromObject({ filter: data.filter });
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
  const localToken = localStorage === undefined ? null : localStorage.token;
  return window.fetch(`${BASE_URL}/orders/export?${params}`, {
    method: "get",
    headers: {
      Authorization: localToken ? `Bearer ${localToken}` : undefined,
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};

const DeleteCustomer = (data: DeleteCustomerParams) => {
  return request(`customers/${data.id}`, "delete");
};

export {
  CreateOrder,
  PreviewOrder,
  ConfirmOrder,
  GetOrders,
  GetOrder,
  UpdateOrder,
  GetCustomers,
  GetCustomer,
  UpdateCustomer,
  CreateCustomer,
  GetStoreOrderStatistics,
  DeleteCustomer,
  GenerateInvoiceFromOrder,
  GetCouponOrders,
  ExportOrders,
  UpdateOrderPaymentStatus,
};
