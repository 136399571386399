import { paramsFromObject } from "../assets/js/utils/functions";
import {
  InvoiceParams,
  CreateDraftParams,
  CreateInvoiceParams,
  GetInvoicesParams,
  UpdateInvoiceParams,
  SendInvoiceViaMailParams,
  CreatePaymentLinkParams,
} from "./interfaces/invoices.interface";
import { request } from "./utils";

const CreateInvoice = (data: CreateInvoiceParams) => {
  return request("invoices", "post", { data });
};

const UpdateInvoice = (data: UpdateInvoiceParams) => {
  return request(`invoices/${data.invoiceId}`, "put", { data: data.data });
};

const DraftInvoice = (data: CreateDraftParams) => {
  return request("invoices/draft", "post", { data });
};

const GetInvoiceStats = () => {
  return request("invoices/statistics", "get");
};
const GetInvoices = (data: GetInvoicesParams) => {
  const params = paramsFromObject(data);
  return request(`invoices?${params}`, "get");
};

const DownloadInvoicePdf = async (data: InvoiceParams) => {
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
  return await fetch(`${BASE_URL}/invoices/pdf/${data.invoiceId}`, {});
};

const UpdateInvoiceToPaid = (data: InvoiceParams) => {
  return request(`invoices/${data.invoiceId}/paid`, "put");
};

const DeleteInvoice = (data: InvoiceParams) => {
  return request(`invoices/${data.invoiceId}`, "delete");
};

const GetPaymentsSetupProgress = () => {
  return request("invoices/setup-progress", "get");
};

const SendInvoiceViaMail = (data: SendInvoiceViaMailParams) => {
  return request(`invoices/${data.id}/mail`, "post", { data: data.data });
};

const CreatePaymentLink = (data: CreatePaymentLinkParams) => {
  return request("invoices/payment-link", "post", { data });
};

const UpdatePaymentLink = (data: CreatePaymentLinkParams) => {
  const { id, ...rest } = data;
  return request(`invoices/payment-link/${id}`, "put", { data: rest });
};

export {
  CreateInvoice,
  DraftInvoice,
  GetInvoiceStats,
  GetInvoices,
  DownloadInvoicePdf,
  UpdateInvoiceToPaid,
  DeleteInvoice,
  GetPaymentsSetupProgress,
  UpdateInvoice,
  SendInvoiceViaMail,
  CreatePaymentLink,
  UpdatePaymentLink,
};
