import { paramsFromObject } from "@/assets/js/utils/functions";
import { CreateCartParams, GetCartParams, GetCartsParams, UpdateCartParams } from "./interfaces/carts.interface";
import { request } from "./utils";

const CreateCart = (data: CreateCartParams) => {
  return request("carts", "post", { data });
};

const UpdateCart = (data: UpdateCartParams) => {
  const cartId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`carts/${cartId}`, "put", { data: dataCopy });
};

const GetCart = (data: GetCartParams) => {
  return request(`carts/${data.id}`, "get");
};

const GetCarts = (data: GetCartsParams) => {
  return request(`carts?${paramsFromObject(data)}`, "get");
};

export { CreateCart, UpdateCart, GetCart, GetCarts };
