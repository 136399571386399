import { paramsFromObject } from "../assets/js/utils/functions";
import {
  GetStoreAnalyticsParams,
  GetStoreTopProductsParams,
  SaveAnalyticsParams,
} from "./interfaces/analytics.interface";

import { request } from "./utils";

const SaveAnalyticsData = (data: SaveAnalyticsParams) => {
  return request("analytics", "post", { data });
};

const GetStoreAnalytics = (data: GetStoreAnalyticsParams) => {
  const params = paramsFromObject({ filter: data.filter });
  return request(`stores/${data.id}/statistics?${params}`, "get");
};

const GetStoreTopProducts = (data: GetStoreTopProductsParams) => {
  return request(`stores/${data.id}/statistics/top-items`, "get");
};

export { SaveAnalyticsData, GetStoreAnalytics, GetStoreTopProducts };
