import { request } from "./utils";

interface GenerateReceiptParams {
  order_id: string;
}

interface FetchReceiptsParams {
  receiptId: string;
}

const GenerateReceiptFromOrder = (data: GenerateReceiptParams) => {
  return request(`receipts/generate-from-order`, "post", { data });
};

const FetchReceipt = (data: FetchReceiptsParams) => {
  return request(`receipts/${data.receiptId}`, "get");
};

const DownloadReceiptPDF = async (data: FetchReceiptsParams) => {
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
 try {
    const res = await fetch(`${BASE_URL}/receipts/pdf/${data.receiptId}`, {});
    return res;
  } catch (err) {
    return err;
  }
};

export { GenerateReceiptFromOrder, FetchReceipt, DownloadReceiptPDF };
