import {
  LookupBVNParams,
  LookupPhoneParams,
  ResendBVNTokenParams,
  ResendPhoneTokenParams,
  SaveAddressParams,
  SavekYCBasicInfoParams,
  UpdateDobParams,
  VerifyBVNParms,
  VerifyIDParams,
  VerifyPhoneParams,
} from "./interfaces/store.kyc.interface";
import { request } from "./utils";

const GetStoreKYC = () => {
  return request("kyc", "get");
};

const SaveKYCBasicInfo = (data: SavekYCBasicInfoParams) => {
  return request("kyc/basic-info", "post", { data });
};

const LookupBVN = (data: LookupBVNParams) => {
  return request("kyc/lookup/bvn", "post", { data });
};

const LookupPhone = (data: LookupPhoneParams) => {
  return request("kyc/lookup/phone", "post", { data });
};

const UpdateDob = (data: UpdateDobParams) => {
  return request("kyc/dob", "put", { data });
};

const VerifyBVN = (data: VerifyBVNParms) => {
  return request("kyc/verify/bvn", "post", { data });
};

const VerifyPhone = (data: VerifyPhoneParams) => {
  return request("kyc/verify/phone", "post", { data });
};
const ResendBVNToken = (data: ResendBVNTokenParams) => {
  return request("kyc/verify/resend-bvn-token", "post", { data });
};

const ResendPhoneToken = (data: ResendPhoneTokenParams) => {
  return request("kyc/verify/resend-phone-token", "post", { data });
};

const VerifyID = (data: VerifyIDParams) => {
  return request("kyc/verify/id", "post", { data });
};

const SaveAddress = (data: SaveAddressParams) => {
  return request("kyc/address", "post", { data });
};

const SubmitKYC = () => {
  return request("kyc/submit", "post", {});
};

const CheckRelatedKyc = () => {
  return request("kyc/check-related-kyc", "get");
};

const CopyKycInfo = (data: { kyc_id: string }) => {
  return request("kyc/copy-info", "post", { data });
};

const SetKycAsManual = () => {
  return request("kyc/manual", "post", {});
};

const ManuallyVerifyID = (data: VerifyIDParams) => {
  return request("kyc/manual/id", "post", { data });
};

const RemoveIdInfo = () => {
  return request("kyc/remove-id", "put", {});
};

export {
  GetStoreKYC,
  SaveKYCBasicInfo,
  LookupBVN,
  VerifyBVN,
  ResendBVNToken,
  VerifyID,
  SaveAddress,
  SubmitKYC,
  CheckRelatedKyc,
  CopyKycInfo,
  SetKycAsManual,
  ManuallyVerifyID,
  RemoveIdInfo,
  LookupPhone,
  VerifyPhone,
  ResendPhoneToken,
  UpdateDob,
};
