import { CURRENCIES } from "@/assets/interfaces";
import { paramsFromObject } from "../assets/js/utils/functions";
import {
  CompleteWithdrawalParams,
  CreatePaymentStatementParams,
  CreateWithdrawalAccountParams,
  DeleteWithdrawalAccountParams,
  GenerateQuoteParams,
  GetCurrencyConversionRatesParams,
  InitiateConversionParams,
  InternationalPaymentRequestParams,
  PaymentStatementSearchParams,
  RequestWithdrawalParams,
  ResolveAccountParams,
  TransactionSearchParams,
} from "./interfaces";
import { request } from "./utils";

const GetStoreWallet = () => {
  return request("wallets", "get");
};

const GetStoreWallets = () => {
  return request("wallets/multiple", "get");
};

const GetWalletBalance = () => {
  return request("wallets/balance", "get");
};

const GetStoreTransactions = (data: TransactionSearchParams) => {
  const { walletId, ...rest } = data;
  const params = paramsFromObject(rest);
  return request(`wallets/${walletId}/transactions?${params}`, "get");
};

const GetWithdrawalAccounts = (data: { currency: CURRENCIES }) => {
  return request(`wallets/withdrawal-accounts?${paramsFromObject(data)}`, "get");
};

const GetBanks = (data: { currency: CURRENCIES }) => {
  return request(`wallets/get-banks?${paramsFromObject(data)}`, "get");
};

const ResolveAccountDetails = (data: ResolveAccountParams) => {
  return request("wallets/resolve-account", "post", { data });
};

const CreateWithdrawalAccount = (data: CreateWithdrawalAccountParams) => {
  return request("wallets/withdrawal-accounts", "post", { data });
};

const DeleteWithdrawalAccount = (data: DeleteWithdrawalAccountParams) => {
  return request("wallets/withdrawal-accounts", "delete", { data });
};

const RequestWithdrawal = (data: RequestWithdrawalParams) => {
  return request("wallets/withdrawal", "post", { data });
};

const CompleteWithdrawal = (data: CompleteWithdrawalParams) => {
  return request("wallets/withdrawal/submit", "post", { data });
};

const InitiateTestPayment = () => {
  return request("wallets/test-payment", "post", {});
};

const GetWithdrawalFees = (data: { currency: CURRENCIES }) => {
  return request(`wallets/withdrawal-fees?currency=${data.currency}`, "get");
};

const ResendWithdrawalToken = (data: { request: string }) => {
  return request(`wallets/withdrawal/${data.request}/resend-token`, "post", {});
};

const CreatePaymentStatement = (data: CreatePaymentStatementParams) => {
  return request("wallets/statements", "post", { data });
};
const GetPaymentStatements = (data: PaymentStatementSearchParams) => {
  const params = paramsFromObject(data);
  return request(`wallets/statements?${params}`, "get");
};

const DownloadStatementPdf = async (data: { id: string; pin: string }) => {
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
  return await fetch(`${BASE_URL}/wallets/statements/pdf/${data.id}?pin=${data.pin}`);
};

const RequestInternationalPayment = (data: InternationalPaymentRequestParams) => {
  return request("wallets/request-wallet", "post", { data });
};

const GetLatestWalletRequest = () => {
  return request("wallets/latest-wallet-request", "get");
};

const GetCurrencyConversionRates = (data: GetCurrencyConversionRatesParams) => {
  const params = paramsFromObject(data);
  return request(`currency-conversion?${params}`, "get");
};

const GetAllConversionRates = () => {
  return request("currency-conversion", "get");
};

const GenerateQuote = (data: GenerateQuoteParams) => {
  return request("currency-conversion/generate-quote", "post", { data });
};

const InitiateConversion = (data: InitiateConversionParams) => {
  return request("currency-conversion/initiate-conversion", "post", { data });
};

export {
  GetStoreWallet,
  GetStoreWallets,
  GetStoreTransactions,
  GetWithdrawalAccounts,
  GetBanks,
  ResolveAccountDetails,
  CreateWithdrawalAccount,
  DeleteWithdrawalAccount,
  RequestWithdrawal,
  RequestInternationalPayment,
  CompleteWithdrawal,
  InitiateTestPayment,
  GetWithdrawalFees,
  ResendWithdrawalToken,
  CreatePaymentStatement,
  GetPaymentStatements,
  DownloadStatementPdf,
  GetWalletBalance,
  GetCurrencyConversionRates,
  GetAllConversionRates,
  GenerateQuote,
  InitiateConversion,
  GetLatestWalletRequest,
};
